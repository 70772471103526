.pf-c-page__sidebar.pf-m-light {
  --pf-global--Color--100: var(--pf-global--Color--dark-100);
  --pf-global--Color--200: var(--pf-global--Color--dark-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--dark-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--dark-100);
  --pf-global--link--Color: var(--pf-global--link--Color--dark);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--dark--hover);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--light-100); }

.pf-c-page__header, .pf-c-page__main-section[class*="pf-m-dark-"] {
  --pf-global--Color--100: var(--pf-global--Color--light-100);
  --pf-global--Color--200: var(--pf-global--Color--light-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--light-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--light-100);
  --pf-global--link--Color: var(--pf-global--link--Color--light);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--light);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--dark-100); }
  .pf-c-page__header .pf-c-card, .pf-c-page__main-section[class*="pf-m-dark-"] .pf-c-card {
    --pf-c-card--BackgroundColor: var(--pf-global--BackgroundColor--dark-transparent-200); }
  .pf-c-page__header .pf-c-button, .pf-c-page__main-section[class*="pf-m-dark-"] .pf-c-button {
    --pf-c-button--m-primary--Color: var(--pf-global--primary-color--dark-100);
    --pf-c-button--m-primary--hover--Color: var(--pf-global--primary-color--dark-100);
    --pf-c-button--m-primary--focus--Color: var(--pf-global--primary-color--dark-100);
    --pf-c-button--m-primary--active--Color: var(--pf-global--primary-color--dark-100);
    --pf-c-button--m-primary--BackgroundColor: var(--pf-global--BackgroundColor--light-100);
    --pf-c-button--m-primary--hover--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
    --pf-c-button--m-primary--focus--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
    --pf-c-button--m-primary--active--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
    --pf-c-button--m-secondary--Color: var(--pf-global--Color--light-100);
    --pf-c-button--m-secondary--hover--Color: var(--pf-global--Color--light-100);
    --pf-c-button--m-secondary--focus--Color: var(--pf-global--Color--light-100);
    --pf-c-button--m-secondary--active--Color: var(--pf-global--Color--light-100);
    --pf-c-button--m-secondary--BorderColor: var(--pf-global--Color--light-100);
    --pf-c-button--m-secondary--hover--BorderColor: var(--pf-global--Color--light-100);
    --pf-c-button--m-secondary--focus--BorderColor: var(--pf-global--Color--light-100);
    --pf-c-button--m-secondary--active--BorderColor: var(--pf-global--Color--light-100); }

.pf-c-page__header-tools-group, .pf-c-page__header-tools-item {
  --pf-hidden-visible--visible--Visibility: visible;
  --pf-hidden-visible--hidden--Display: none;
  --pf-hidden-visible--hidden--Visibility: hidden;
  --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
  --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility);
  display: var(--pf-hidden-visible--Display);
  visibility: var(--pf-hidden-visible--Visibility); }
  .pf-m-hidden.pf-c-page__header-tools-group, .pf-m-hidden.pf-c-page__header-tools-item {
    --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
    --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
  @media screen and (min-width: 576px) {
    .pf-m-hidden-on-sm.pf-c-page__header-tools-group, .pf-m-hidden-on-sm.pf-c-page__header-tools-item {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-sm.pf-c-page__header-tools-group, .pf-m-visible-on-sm.pf-c-page__header-tools-item {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }
  @media screen and (min-width: 768px) {
    .pf-m-hidden-on-md.pf-c-page__header-tools-group, .pf-m-hidden-on-md.pf-c-page__header-tools-item {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-md.pf-c-page__header-tools-group, .pf-m-visible-on-md.pf-c-page__header-tools-item {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }
  @media screen and (min-width: 992px) {
    .pf-m-hidden-on-lg.pf-c-page__header-tools-group, .pf-m-hidden-on-lg.pf-c-page__header-tools-item {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-lg.pf-c-page__header-tools-group, .pf-m-visible-on-lg.pf-c-page__header-tools-item {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }
  @media screen and (min-width: 1200px) {
    .pf-m-hidden-on-xl.pf-c-page__header-tools-group, .pf-m-hidden-on-xl.pf-c-page__header-tools-item {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-xl.pf-c-page__header-tools-group, .pf-m-visible-on-xl.pf-c-page__header-tools-item {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }
  @media screen and (min-width: 1450px) {
    .pf-m-hidden-on-2xl.pf-c-page__header-tools-group, .pf-m-hidden-on-2xl.pf-c-page__header-tools-item {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-2xl.pf-c-page__header-tools-group, .pf-m-visible-on-2xl.pf-c-page__header-tools-item {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }

.pf-c-page {
  --pf-c-page--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-page__header--BackgroundColor: var(--pf-global--BackgroundColor--dark-100);
  --pf-c-page__header--ZIndex: var(--pf-global--ZIndex--md);
  --pf-c-page__header--MinHeight: 4.75rem;
  --pf-c-page__header-brand--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-page__header-brand--xl--PaddingRight: var(--pf-global--spacer--xl);
  --pf-c-page__header-brand--xl--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-page__header-sidebar-toggle__c-button--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-page__header-sidebar-toggle__c-button--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-page__header-sidebar-toggle__c-button--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-page__header-sidebar-toggle__c-button--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-page__header-sidebar-toggle__c-button--MarginRight: var(--pf-global--spacer--md);
  --pf-c-page__header-sidebar-toggle__c-button--MarginLeft: calc(var(--pf-c-page__header-sidebar-toggle__c-button--PaddingLeft) * -1);
  --pf-c-page__header-sidebar-toggle__c-button--FontSize: var(--pf-global--FontSize--2xl);
  --pf-c-page__header-brand-link--c-brand--MaxHeight: 3.75rem;
  --pf-c-page__header-nav--BackgroundColor: var(--pf-global--BackgroundColor--dark-300);
  --pf-c-page__header-nav--xl--BackgroundColor: transparent;
  --pf-c-page__header-nav--xl--PaddingRight: var(--pf-global--spacer--xl);
  --pf-c-page__header-nav--xl--PaddingLeft: var(--pf-global--spacer--xl);
  --pf-c-page__header-tools--MarginRight: var(--pf-global--spacer--md);
  --pf-c-page__header-tools--xl--MarginRight: var(--pf-global--spacer--lg);
  --pf-c-page__header-tools--c-avatar--MarginLeft: var(--pf-global--spacer--md);
  --pf-c-page__header-tools-group--MarginLeft: var(--pf-global--spacer--xl);
  --pf-c-page__header-tools-group--Display: flex;
  --pf-c-page__header-tools-item--Display: block;
  --pf-c-page__header-tools-item--c-notification-badge--hover--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-page__header-tools--c-button--m-selected--before--Width: auto;
  --pf-c-page__header-tools--c-button--m-selected--before--Height: auto;
  --pf-c-page__header-tools--c-button--m-selected--before--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-page__header-tools--c-button--m-selected--before--BorderRadius: var(--pf-global--BorderRadius--sm);
  --pf-c-page__header-tools--c-button--m-selected--c-notification-badge--m-unread--after--BorderColor: transparent;
  --pf-c-page__sidebar--ZIndex: var(--pf-global--ZIndex--sm);
  --pf-c-page__sidebar--Width: 80%;
  --pf-c-page__sidebar--Width: 18.125rem;
  --pf-c-page__sidebar--BackgroundColor: var(--pf-global--BackgroundColor--dark-300);
  --pf-c-page__sidebar--m-light--BackgroundColor: var(--pf-global--BackgroundColor--light-100);
  --pf-c-page__sidebar--BoxShadow: var(--pf-global--BoxShadow--lg-right);
  --pf-c-page__sidebar--Transition: var(--pf-global--Transition);
  --pf-c-page__sidebar--TranslateX: -100%;
  --pf-c-page__sidebar--TranslateZ: 0;
  --pf-c-page__sidebar--m-expanded--TranslateX: 0;
  --pf-c-page__sidebar--xl--TranslateX: 0;
  --pf-c-page__sidebar-body--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-page__sidebar-body--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-page__main-section--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-page__main-section--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-page__main-section--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-page__main-section--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-page__main-section--xl--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-page__main-section--xl--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-page__main-section--xl--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-page__main-section--xl--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-page__main-section--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-page__main--ZIndex: var(--pf-global--ZIndex--xs);
  --pf-c-page__main-breadcrumb--main-section--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-page--section--m-limit-width--MaxWidth: calc(125rem - var(--pf-c-page__sidebar--Width));
  --pf-c-page__main-nav--BackgroundColor: var(--pf-global--BackgroundColor--light-100);
  --pf-c-page__main-nav--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-page__main-nav--PaddingRight: 0;
  --pf-c-page__main-nav--PaddingLeft: 0;
  --pf-c-page__main-nav--xl--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-page__main-nav--xl--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-page__main-breadcrumb--BackgroundColor: var(--pf-global--BackgroundColor--light-100);
  --pf-c-page__main-breadcrumb--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-page__main-breadcrumb--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-page__main-breadcrumb--PaddingBottom: 0;
  --pf-c-page__main-breadcrumb--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-page__main-breadcrumb--xl--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-page__main-breadcrumb--xl--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-page__main-section--m-light--BackgroundColor: var(--pf-global--BackgroundColor--light-100);
  --pf-c-page__main-section--m-dark-100--BackgroundColor: var(--pf-global--BackgroundColor--dark-transparent-100);
  --pf-c-page__main-section--m-dark-200--BackgroundColor: var(--pf-global--BackgroundColor--dark-transparent-200);
  --pf-c-page__main-wizard--BackgroundColor: var(--pf-global--BackgroundColor--light-100);
  --pf-c-page__main-wizard--BorderTopColor: var(--pf-global--BorderColor--100);
  --pf-c-page__main-wizard--BorderTopWidth: var(--pf-global--BorderWidth--sm);
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  grid-template-areas: "header" "main";
  background-color: var(--pf-c-page--BackgroundColor); }
  @media (min-width: 1200px) {
    .pf-c-page {
      --pf-c-page__header-brand--PaddingLeft: var(--pf-c-page__header-brand--xl--PaddingLeft); } }
  @media screen and (min-width: 1200px) {
    .pf-c-page {
      --pf-c-page__header-nav--BackgroundColor: var(--pf-c-page__header-nav--xl--BackgroundColor);
      --pf-c-page__header-nav--PaddingRight: var(--pf-c-page__header-nav--xl--PaddingRight);
      --pf-c-page__header-nav--PaddingLeft: var(--pf-c-page__header-nav--xl--PaddingLeft); } }
  @media screen and (min-width: 1200px) {
    .pf-c-page {
      --pf-c-page__header-tools--MarginRight: var(--pf-c-page__header-tools--xl--MarginRight); } }
  @media screen and (min-width: 1200px) {
    .pf-c-page {
      --pf-c-page__sidebar--TranslateX: var(--pf-c-page__sidebar--xl--TranslateX); } }
  @media screen and (min-width: 1200px) {
    .pf-c-page {
      --pf-c-page__main-section--PaddingTop: var(--pf-c-page__main-section--xl--PaddingTop);
      --pf-c-page__main-section--PaddingRight: var(--pf-c-page__main-section--xl--PaddingRight);
      --pf-c-page__main-section--PaddingBottom: var(--pf-c-page__main-section--xl--PaddingBottom);
      --pf-c-page__main-section--PaddingLeft: var(--pf-c-page__main-section--xl--PaddingLeft); } }
  @media screen and (min-width: 1200px) {
    .pf-c-page {
      --pf-c-page__main-nav--PaddingRight: var(--pf-c-page__main-nav--xl--PaddingRight);
      --pf-c-page__main-nav--PaddingLeft: var(--pf-c-page__main-nav--xl--PaddingLeft); } }
  @media screen and (min-width: 1200px) {
    .pf-c-page {
      --pf-c-page__main-breadcrumb--PaddingRight: var(--pf-c-page__main-breadcrumb--xl--PaddingRight);
      --pf-c-page__main-breadcrumb--PaddingLeft: var(--pf-c-page__main-breadcrumb--xl--PaddingLeft); } }
  @media (min-width: 1200px) {
    .pf-c-page {
      grid-template-columns: max-content 1fr;
      grid-template-areas: "header header" "nav main"; } }

.pf-c-page__header {
  color: var(--pf-global--Color--100);
  z-index: var(--pf-c-page__header--ZIndex);
  grid-template-columns: auto auto;
  display: grid;
  grid-area: header;
  align-items: center;
  min-width: 0;
  min-height: var(--pf-c-page__header--MinHeight);
  background-color: var(--pf-c-page__header--BackgroundColor); }
  .pf-c-page__header > * {
    display: flex;
    align-items: center; }
  @media screen and (min-width: 992px) {
    .pf-c-page__header {
      grid-template-columns: auto 1fr auto; } }

.pf-c-page__header-brand {
  grid-column: 1 / 2;
  padding-left: var(--pf-c-page__header-brand--PaddingLeft); }
  @media (min-width: 1200px) {
    .pf-c-page__header-brand {
      padding-right: var(--pf-c-page__header-brand--xl--PaddingRight); } }

.pf-c-page__header-brand-link {
  display: flex;
  flex: 1;
  align-items: center; }
  .pf-c-page__header-brand-link .pf-c-brand {
    max-height: var(--pf-c-page__header-brand-link--c-brand--MaxHeight); }

.pf-c-page__header-brand-toggle .pf-c-button {
  padding: var(--pf-c-page__header-sidebar-toggle__c-button--PaddingTop) var(--pf-c-page__header-sidebar-toggle__c-button--PaddingRight) var(--pf-c-page__header-sidebar-toggle__c-button--PaddingBottom) var(--pf-c-page__header-sidebar-toggle__c-button--PaddingLeft);
  margin-right: var(--pf-c-page__header-sidebar-toggle__c-button--MarginRight);
  margin-left: var(--pf-c-page__header-sidebar-toggle__c-button--MarginLeft);
  font-size: var(--pf-c-page__header-sidebar-toggle__c-button--FontSize);
  line-height: 1; }

.pf-c-page__header-nav {
  align-self: stretch;
  min-width: 0;
  padding-right: var(--pf-c-page__header-nav--PaddingRight);
  padding-left: var(--pf-c-page__header-nav--PaddingLeft);
  background-color: var(--pf-c-page__header-nav--BackgroundColor);
  grid-column: 1 / -1;
  grid-row: 2 / 3; }
  @media screen and (min-width: 1200px) {
    .pf-c-page__header-nav {
      grid-column: 2 / 3;
      grid-row: 1 / 2; } }
  .pf-c-page__header-nav .pf-c-nav {
    align-self: stretch; }

.pf-c-page__header-tools {
  grid-column: 2 / 3;
  margin-right: var(--pf-c-page__header-tools--MarginRight);
  margin-left: auto; }
  .pf-c-page__header-tools .pf-c-avatar {
    margin-left: var(--pf-c-page__header-tools--c-avatar--MarginLeft); }
  @media screen and (min-width: 992px) {
    .pf-c-page__header-tools {
      grid-column: 3 / 4; } }

.pf-c-page__header-tools-group {
  --pf-hidden-visible--visible--Display: var(--pf-c-page__header-tools-group--Display);
  align-items: center; }
  .pf-c-page__header-tools-group + .pf-c-page__header-tools-group {
    margin-left: var(--pf-c-page__header-tools-group--MarginLeft); }

.pf-c-page__header-tools-item {
  --pf-hidden-visible--visible--Display: var(--pf-c-page__header-tools-item--Display); }
  .pf-c-page__header-tools-item .pf-c-notification-badge.pf-m-read:hover {
    --pf-c-notification-badge--after--BackgroundColor: var(--pf-c-page__header-tools-item--c-notification-badge--hover--BackgroundColor); }
  .pf-c-page__header-tools-item.pf-m-selected .pf-c-button {
    background-color: var(--pf-c-page__header-tools--c-button--m-selected--before--BackgroundColor);
    border-radius: var(--pf-c-page__header-tools--c-button--m-selected--before--BorderRadius); }
    .pf-c-page__header-tools-item.pf-m-selected .pf-c-button::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: var(--pf-c-page__header-tools--c-button--m-selected--before--Width);
      height: var(--pf-c-page__header-tools--c-button--m-selected--before--Height);
      content: ""; }
    .pf-c-page__header-tools-item.pf-m-selected .pf-c-button .pf-c-notification-badge.pf-m-unread::after {
      border-color: var(--pf-c-page__header-tools--c-button--m-selected--c-notification-badge--m-unread--after--BorderColor); }

.pf-c-page__sidebar {
  grid-area: nav;
  grid-row-start: 2;
  grid-column-start: 1;
  z-index: var(--pf-c-page__sidebar--ZIndex);
  width: var(--pf-c-page__sidebar--Width);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: var(--pf-c-page__sidebar--BackgroundColor);
  transition: var(--pf-c-page__sidebar--Transition);
  transform: translateX(var(--pf-c-page__sidebar--TranslateX)) translateZ(var(--pf-c-page__sidebar--TranslateZ)); }
  @media screen and (min-width: 1200px) {
    .pf-c-page__sidebar {
      box-shadow: var(--pf-c-page__sidebar--BoxShadow); } }
  .pf-c-page__sidebar.pf-m-expanded {
    --pf-c-page__sidebar--TranslateX: var(--pf-c-page__sidebar--m-expanded--TranslateX);
    box-shadow: var(--pf-c-page__sidebar--BoxShadow); }
  .pf-c-page__sidebar.pf-m-collapsed {
    max-width: 0;
    overflow: hidden; }
  .pf-c-page__sidebar.pf-m-light {
    color: var(--pf-global--Color--100);
    --pf-c-page__sidebar--BackgroundColor: var(--pf-c-page__sidebar--m-light--BackgroundColor); }

.pf-c-page__sidebar-body {
  padding-top: var(--pf-c-page__sidebar-body--PaddingTop);
  padding-bottom: var(--pf-c-page__sidebar-body--PaddingBottom); }

.pf-c-page__main,
.pf-c-page__drawer {
  grid-area: main;
  z-index: var(--pf-c-page__main--ZIndex);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .pf-c-page__main:focus,
  .pf-c-page__drawer:focus {
    outline: 0; }

.pf-c-page__main-nav {
  padding-top: var(--pf-c-page__main-nav--PaddingTop);
  padding-right: var(--pf-c-page__main-nav--PaddingRight);
  padding-left: var(--pf-c-page__main-nav--PaddingLeft);
  background-color: var(--pf-c-page__main-nav--BackgroundColor); }

.pf-c-page__main-breadcrumb {
  padding: var(--pf-c-page__main-breadcrumb--PaddingTop) var(--pf-c-page__main-breadcrumb--PaddingRight) var(--pf-c-page__main-breadcrumb--PaddingBottom) var(--pf-c-page__main-breadcrumb--PaddingLeft);
  background-color: var(--pf-c-page__main-breadcrumb--BackgroundColor); }
  .pf-c-page__main-breadcrumb + .pf-c-page__main-section {
    --pf-c-page__main-section--PaddingTop: var(--pf-c-page__main-breadcrumb--main-section--PaddingTop); }

.pf-c-page__main-section {
  padding: var(--pf-c-page__main-section--PaddingTop) var(--pf-c-page__main-section--PaddingRight) var(--pf-c-page__main-section--PaddingBottom) var(--pf-c-page__main-section--PaddingLeft);
  background-color: var(--pf-c-page__main-section--BackgroundColor); }
  .pf-c-page__main-section:last-of-type, .pf-c-page__main-section:only-child, .pf-c-page__main-section.pf-m-fill {
    flex-grow: 1; }
  .pf-c-page__main-section.pf-m-no-fill {
    flex-grow: 0; }
  .pf-c-page__main-section.pf-m-light {
    --pf-c-page__main-section--BackgroundColor: var(--pf-c-page__main-section--m-light--BackgroundColor); }
  .pf-c-page__main-section[class*="pf-m-dark-"] {
    color: var(--pf-global--Color--100); }
  .pf-c-page__main-section.pf-m-dark-100 {
    --pf-c-page__main-section--BackgroundColor: var(--pf-c-page__main-section--m-dark-100--BackgroundColor); }
  .pf-c-page__main-section.pf-m-dark-200 {
    --pf-c-page__main-section--BackgroundColor: var(--pf-c-page__main-section--m-dark-200--BackgroundColor); }
  .pf-c-page__main-section.pf-m-padding {
    padding: var(--pf-c-page__main-section--PaddingTop) var(--pf-c-page__main-section--PaddingRight) var(--pf-c-page__main-section--PaddingBottom) var(--pf-c-page__main-section--PaddingLeft); }
  .pf-c-page__main-section.pf-m-no-padding {
    --pf-c-page__main-section--PaddingTop: 0;
    --pf-c-page__main-section--PaddingRight: 0;
    --pf-c-page__main-section--PaddingBottom: 0;
    --pf-c-page__main-section--PaddingLeft: 0; }
  @media (min-width: 576px) {
    .pf-c-page__main-section.pf-m-padding-on-sm {
      padding: var(--pf-c-page__main-section--PaddingTop) var(--pf-c-page__main-section--PaddingRight) var(--pf-c-page__main-section--PaddingBottom) var(--pf-c-page__main-section--PaddingLeft); }
    .pf-c-page__main-section.pf-m-no-padding-on-sm {
      --pf-c-page__main-section--PaddingTop: 0;
      --pf-c-page__main-section--PaddingRight: 0;
      --pf-c-page__main-section--PaddingBottom: 0;
      --pf-c-page__main-section--PaddingLeft: 0; } }
  @media (min-width: 768px) {
    .pf-c-page__main-section.pf-m-padding-on-md {
      padding: var(--pf-c-page__main-section--PaddingTop) var(--pf-c-page__main-section--PaddingRight) var(--pf-c-page__main-section--PaddingBottom) var(--pf-c-page__main-section--PaddingLeft); }
    .pf-c-page__main-section.pf-m-no-padding-on-md {
      --pf-c-page__main-section--PaddingTop: 0;
      --pf-c-page__main-section--PaddingRight: 0;
      --pf-c-page__main-section--PaddingBottom: 0;
      --pf-c-page__main-section--PaddingLeft: 0; } }
  @media (min-width: 992px) {
    .pf-c-page__main-section.pf-m-padding-on-lg {
      padding: var(--pf-c-page__main-section--PaddingTop) var(--pf-c-page__main-section--PaddingRight) var(--pf-c-page__main-section--PaddingBottom) var(--pf-c-page__main-section--PaddingLeft); }
    .pf-c-page__main-section.pf-m-no-padding-on-lg {
      --pf-c-page__main-section--PaddingTop: 0;
      --pf-c-page__main-section--PaddingRight: 0;
      --pf-c-page__main-section--PaddingBottom: 0;
      --pf-c-page__main-section--PaddingLeft: 0; } }
  @media (min-width: 1200px) {
    .pf-c-page__main-section.pf-m-padding-on-xl {
      padding: var(--pf-c-page__main-section--PaddingTop) var(--pf-c-page__main-section--PaddingRight) var(--pf-c-page__main-section--PaddingBottom) var(--pf-c-page__main-section--PaddingLeft); }
    .pf-c-page__main-section.pf-m-no-padding-on-xl {
      --pf-c-page__main-section--PaddingTop: 0;
      --pf-c-page__main-section--PaddingRight: 0;
      --pf-c-page__main-section--PaddingBottom: 0;
      --pf-c-page__main-section--PaddingLeft: 0; } }
  @media (min-width: 1450px) {
    .pf-c-page__main-section.pf-m-padding-on-2xl {
      padding: var(--pf-c-page__main-section--PaddingTop) var(--pf-c-page__main-section--PaddingRight) var(--pf-c-page__main-section--PaddingBottom) var(--pf-c-page__main-section--PaddingLeft); }
    .pf-c-page__main-section.pf-m-no-padding-on-2xl {
      --pf-c-page__main-section--PaddingTop: 0;
      --pf-c-page__main-section--PaddingRight: 0;
      --pf-c-page__main-section--PaddingBottom: 0;
      --pf-c-page__main-section--PaddingLeft: 0; } }

.pf-c-page__main-wizard {
  flex-grow: 1;
  background-color: var(--pf-c-page__main-wizard--BackgroundColor);
  border-top: var(--pf-c-page__main-wizard--BorderTopWidth) solid var(--pf-c-page__main-wizard--BorderTopColor); }

.pf-c-page__main-nav,
.pf-c-page__main-breadcrumb,
.pf-c-page__main-section,
.pf-c-page__main-wizard {
  flex-shrink: 0; }
  .pf-c-page__main-nav.pf-m-limit-width,
  .pf-c-page__main-breadcrumb.pf-m-limit-width,
  .pf-c-page__main-section.pf-m-limit-width,
  .pf-c-page__main-wizard.pf-m-limit-width {
    display: flex;
    flex-direction: column;
    padding: 0; }
    .pf-c-page__main-nav.pf-m-limit-width .pf-c-page__main-body,
    .pf-c-page__main-breadcrumb.pf-m-limit-width .pf-c-page__main-body,
    .pf-c-page__main-section.pf-m-limit-width .pf-c-page__main-body,
    .pf-c-page__main-wizard.pf-m-limit-width .pf-c-page__main-body {
      flex: 1;
      max-width: var(--pf-c-page--section--m-limit-width--MaxWidth); }

.pf-c-page__main-nav .pf-c-page__main-body {
  padding-top: var(--pf-c-page__main-nav--PaddingTop);
  padding-right: var(--pf-c-page__main-nav--PaddingRight);
  padding-left: var(--pf-c-page__main-nav--PaddingLeft); }

.pf-c-page__main-breadcrumb .pf-c-page__main-body {
  padding: var(--pf-c-page__main-breadcrumb--PaddingTop) var(--pf-c-page__main-breadcrumb--PaddingRight) var(--pf-c-page__main-breadcrumb--PaddingBottom) var(--pf-c-page__main-breadcrumb--PaddingLeft); }

.pf-c-page__main-section .pf-c-page__main-body {
  padding: var(--pf-c-page__main-section--PaddingTop) var(--pf-c-page__main-section--PaddingRight) var(--pf-c-page__main-section--PaddingBottom) var(--pf-c-page__main-section--PaddingLeft); }

.pf-c-page__drawer {
  grid-area: main; }
  .pf-c-page__drawer > .pf-c-drawer {
    flex: 1 0 auto; }
