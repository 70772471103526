.pf-c-form {
  --pf-c-form--GridGap: var(--pf-global--gutter--md);
  --pf-c-form__group--m-action--MarginTop: var(--pf-global--spacer--xl);
  --pf-c-form--m-horizontal__group-label--md--GridColumnWidth: 9.375rem;
  --pf-c-form--m-horizontal__group-label--md--GridColumnGap: var(--pf-global--spacer--md);
  --pf-c-form--m-horizontal__group-control--md--GridColumnWidth: 1fr;
  --pf-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-form__group-label--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-form__label--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-form__label--LineHeight: var(--pf-global--LineHeight--sm);
  --pf-c-form__label--m-disabled--Color: var(--pf-global--disabled-color--100);
  --pf-c-form__label-text--FontWeight: var(--pf-global--FontWeight--bold);
  --pf-c-form__label-required--MarginLeft: var(--pf-global--spacer--xs);
  --pf-c-form__label-required--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-form__label-required--Color: var(--pf-global--danger-color--100);
  --pf-c-form__group-label-help--PaddingTop: var(--pf-global--spacer--xs);
  --pf-c-form__group-label-help--PaddingRight: var(--pf-global--spacer--xs);
  --pf-c-form__group-label-help--PaddingBottom: var(--pf-global--spacer--xs);
  --pf-c-form__group-label-help--PaddingLeft: var(--pf-global--spacer--xs);
  --pf-c-form__group-label-help--MarginTop: calc(var(--pf-c-form__group-label-help--PaddingTop) * -1);
  --pf-c-form__group-label-help--MarginRight: calc(var(--pf-c-form__group-label-help--PaddingRight) * -1);
  --pf-c-form__group-label-help--MarginBottom: calc(var(--pf-c-form__group-label-help--PaddingBottom) * -1);
  --pf-c-form__group-label-help--MarginLeft: calc(var(--pf-c-form__group-label-help--PaddingLeft) * -1 + var(--pf-global--spacer--xs));
  --pf-c-form__group-label-help--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-form__group-label-help--TranslateY: 0.125rem;
  --pf-c-form__group-control--m-inline--child--MarginRight: var(--pf-global--spacer--lg);
  --pf-c-form__actions--child--MarginTop: var(--pf-global--spacer--sm);
  --pf-c-form__actions--child--MarginRight: var(--pf-global--spacer--sm);
  --pf-c-form__actions--child--MarginBottom: var(--pf-global--spacer--sm);
  --pf-c-form__actions--child--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-form__actions--MarginTop: calc(var(--pf-c-form__actions--child--MarginTop) * -1);
  --pf-c-form__actions--MarginRight: calc(var(--pf-c-form__actions--child--MarginRight) * -1);
  --pf-c-form__actions--MarginBottom: calc(var(--pf-c-form__actions--child--MarginBottom) * -1);
  --pf-c-form__actions--MarginLeft: calc(var(--pf-c-form__actions--child--MarginLeft) * -1);
  --pf-c-form__helper-text--MarginTop: var(--pf-global--spacer--xs);
  --pf-c-form__helper-text--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-form__helper-text--Color: var(--pf-global--Color--100);
  --pf-c-form__helper-text-icon--FontSize: var(--pf-global--FontSize--md);
  --pf-c-form__helper-text-icon--MarginRight: var(--pf-global--spacer--xs);
  --pf-c-form__helper-text--m-success--Color: var(--pf-global--success-color--200);
  --pf-c-form__helper-text--m-warning--Color: var(--pf-global--warning-color--200);
  --pf-c-form__helper-text--m-error--Color: var(--pf-global--danger-color--100);
  display: grid;
  grid-gap: var(--pf-c-form--GridGap); }
  .pf-c-form.pf-m-horizontal {
    --pf-c-form__group-label--PaddingBottom: 0; }
    .pf-c-form.pf-m-horizontal.pf-m-align-right .pf-c-form__label {
      text-align: right; }
    @media (min-width: 768px) {
      .pf-c-form.pf-m-horizontal .pf-c-form__group {
        display: grid;
        grid-column-gap: var(--pf-c-form--m-horizontal__group-label--md--GridColumnGap);
        grid-template-columns: var(--pf-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-c-form--m-horizontal__group-control--md--GridColumnWidth); }
      .pf-c-form.pf-m-horizontal .pf-c-form__group-label {
        padding-top: var(--pf-c-form--m-horizontal__group-label--md--PaddingTop); }
        .pf-c-form.pf-m-horizontal .pf-c-form__group-label.pf-m-no-padding-top {
          --pf-c-form--m-horizontal__group-label--md--PaddingTop: 0; }
      .pf-c-form.pf-m-horizontal .pf-c-form__group-control {
        grid-column: 2; } }

.pf-c-form__group.pf-m-action {
  margin-top: var(--pf-c-form__group--m-action--MarginTop);
  overflow: hidden; }

.pf-c-form__group-label {
  padding-bottom: var(--pf-c-form__group-label--PaddingBottom); }

.pf-c-form__label {
  font-size: var(--pf-c-form__label--FontSize);
  line-height: var(--pf-c-form__label--LineHeight); }
  .pf-c-form__label::selection {
    background-color: none; }
  .pf-c-form__label:not(.pf-m-disabled):hover {
    cursor: pointer; }
  .pf-c-form__label.pf-m-disabled {
    color: var(--pf-c-form__label--m-disabled--Color); }
  .pf-c-form__label.pf-m-disabled:hover {
    cursor: not-allowed; }

.pf-c-form__label-text {
  font-weight: var(--pf-c-form__label-text--FontWeight); }

.pf-c-form__label-required {
  margin-left: var(--pf-c-form__label-required--MarginLeft);
  font-size: var(--pf-c-form__label-required--FontSize);
  color: var(--pf-c-form__label-required--Color); }

.pf-c-form__group-label-help {
  padding-top: var(--pf-c-form__group-label-help--PaddingTop);
  padding-right: var(--pf-c-form__group-label-help--PaddingRight);
  padding-bottom: var(--pf-c-form__group-label-help--PaddingBottom);
  padding-left: var(--pf-c-form__group-label-help--PaddingLeft);
  margin-top: var(--pf-c-form__group-label-help--MarginTop);
  margin-right: var(--pf-c-form__group-label-help--MarginRight);
  margin-bottom: var(--pf-c-form__group-label-help--MarginBottom);
  margin-left: var(--pf-c-form__group-label-help--MarginLeft);
  font-size: var(--pf-c-form__group-label-help--FontSize);
  line-height: 1;
  border: 0;
  transform: translateY(var(--pf-c-form__group-label-help--TranslateY)); }

.pf-c-form__group-control.pf-m-inline {
  display: flex;
  flex-flow: row wrap; }
  .pf-c-form__group-control.pf-m-inline > * {
    margin-right: var(--pf-c-form__group-control--m-inline--child--MarginRight); }

.pf-c-form__helper-text {
  margin-top: var(--pf-c-form__helper-text--MarginTop);
  font-size: var(--pf-c-form__helper-text--FontSize);
  color: var(--pf-c-form__helper-text--Color); }
  .pf-c-form__helper-text.pf-m-error {
    --pf-c-form__helper-text--Color: var(--pf-c-form__helper-text--m-error--Color); }
  .pf-c-form__helper-text.pf-m-success {
    --pf-c-form__helper-text--Color: var(--pf-c-form__helper-text--m-success--Color); }
  .pf-c-form__helper-text.pf-m-warning {
    --pf-c-form__helper-text--Color: var(--pf-c-form__helper-text--m-warning--Color); }
  .pf-c-form__helper-text.pf-m-inactive {
    display: none;
    visibility: hidden; }
  .pf-c-form__helper-text.pf-m-hidden {
    visibility: hidden;
    opacity: 0; }

.pf-c-form__helper-text-icon {
  margin-right: var(--pf-c-form__helper-text-icon--MarginRight);
  font-size: var(--pf-c-form__helper-text-icon--FontSize); }

.pf-c-form__fieldset {
  border: 0; }

.pf-c-form__actions {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--pf-c-form__actions--MarginTop);
  margin-right: var(--pf-c-form__actions--MarginRight);
  margin-bottom: var(--pf-c-form__actions--MarginBottom);
  margin-left: var(--pf-c-form__actions--MarginLeft); }
  .pf-c-form__actions > * {
    margin-top: var(--pf-c-form__actions--child--MarginTop);
    margin-right: var(--pf-c-form__actions--child--MarginRight);
    margin-bottom: var(--pf-c-form__actions--child--MarginBottom);
    margin-left: var(--pf-c-form__actions--child--MarginLeft); }
