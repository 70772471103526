.pf-c-pagination {
  --pf-c-pagination--child--MarginRight: var(--pf-global--spacer--lg);
  --pf-c-pagination--m-bottom--child--MarginRight: 0;
  --pf-c-pagination--m-bottom--child--md--MarginRight: var(--pf-global--spacer--lg);
  --pf-c-pagination--m-compact--child--MarginRight: var(--pf-global--spacer--sm);
  --pf-c-pagination--c-options-menu__toggle--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-pagination__nav-control--c-button--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-pagination__nav-control--c-button--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-pagination__nav-control--c-button--FontSize: var(--pf-global--FontSize--md);
  --pf-c-pagination--m-bottom__nav-control--c-button--OutlineOffset: calc(var(--pf-global--spacer--xs) * -1);
  --pf-c-pagination--m-bottom__nav-control--c-button--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-pagination--m-bottom__nav-control--c-button--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-pagination--m-bottom__nav-control--c-button--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-pagination--m-bottom__nav-control--c-button--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-pagination--m-bottom__nav-control--c-button--md--PaddingTop: var(--pf-global--spacer--form-element);
  --pf-c-pagination--m-bottom__nav-control--c-button--md--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-pagination--m-bottom__nav-control--c-button--md--PaddingBottom: var(--pf-global--spacer--form-element);
  --pf-c-pagination--m-bottom__nav-control--c-button--md--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-pagination--m-compact__nav-control--nav-control--MarginLeft: var(--pf-global--spacer--md);
  --pf-c-pagination__nav-page-select--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-pagination__nav-page-select--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-pagination__nav-page-select--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-pagination__nav-page-select--child--MarginRight: var(--pf-global--spacer--xs);
  --pf-c-pagination__nav-page-select--c-form-control--width-base: 3.5ch;
  --pf-c-pagination__nav-page-select--c-form-control--width-chars: 2;
  --pf-c-pagination__nav-page-select--c-form-control--Width: calc(var(--pf-c-pagination__nav-page-select--c-form-control--width-base) + (var(--pf-c-pagination__nav-page-select--c-form-control--width-chars) * 1ch));
  --pf-c-pagination--m-bottom--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-pagination--m-bottom--BoxShadow: var(--pf-global--BoxShadow--sm-top);
  --pf-c-pagination--m-bottom--md--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-pagination--m-bottom--md--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-pagination--m-bottom--md--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-pagination--m-bottom--md--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-pagination--m-bottom--xl--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-pagination--m-bottom--xl--PaddingLeft: var(--pf-global--spacer--lg);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end; }
  @media screen and (min-width: 768px) {
    .pf-c-pagination {
      --pf-c-pagination--m-bottom__nav-control--c-button--PaddingTop: var(--pf-c-pagination--m-bottom__nav-control--c-button--md--PaddingTop);
      --pf-c-pagination--m-bottom__nav-control--c-button--PaddingRight: var(--pf-c-pagination--m-bottom__nav-control--c-button--md--PaddingRight);
      --pf-c-pagination--m-bottom__nav-control--c-button--PaddingBottom: var(--pf-c-pagination--m-bottom__nav-control--c-button--md--PaddingBottom);
      --pf-c-pagination--m-bottom__nav-control--c-button--PaddingLeft: var(--pf-c-pagination--m-bottom__nav-control--c-button--md--PaddingLeft);
      --pf-c-pagination--m-bottom--child--MarginRight: var(--pf-c-pagination--m-bottom--child--md--MarginRight);
      --pf-c-pagination--m-bottom__nav-control--c-button--OutlineOffset: 0;
      --pf-c-pagination--m-bottom--BoxShadow: none; } }
  @media screen and (min-width: 1200px) {
    .pf-c-pagination {
      --pf-c-pagination--m-bottom--md--PaddingRight: var(--pf-c-pagination--m-bottom--xl--PaddingRight);
      --pf-c-pagination--m-bottom--md--PaddingLeft: var(--pf-c-pagination--m-bottom--xl--PaddingLeft); } }
  .pf-c-pagination > *:not(:last-child):not(.pf-c-pagination__total-items) {
    margin-right: var(--pf-c-pagination--child--MarginRight); }
  .pf-c-pagination:not(.pf-m-bottom) .pf-c-options-menu,
  .pf-c-pagination:not(.pf-m-bottom) .pf-c-pagination__nav {
    display: none;
    visibility: hidden; }
    @media screen and (min-width: 768px) {
      .pf-c-pagination:not(.pf-m-bottom) .pf-c-options-menu,
      .pf-c-pagination:not(.pf-m-bottom) .pf-c-pagination__nav {
        display: flex;
        visibility: visible; } }
  .pf-c-pagination.pf-m-bottom {
    --pf-c-pagination--child--MarginRight: var(--pf-c-pagination--m-bottom--child--MarginRight);
    --pf-c-pagination__nav-control--c-button--PaddingRight: var(--pf-c-pagination--m-bottom__nav-control--c-button--PaddingRight);
    --pf-c-pagination__nav-control--c-button--PaddingLeft: var(--pf-c-pagination--m-bottom__nav-control--c-button--PaddingRight);
    position: sticky;
    bottom: 0;
    justify-content: center;
    padding-top: var(--pf-c-pagination--m-bottom--PaddingTop);
    padding-right: var(--pf-c-pagination--m-bottom--PaddingRight);
    padding-bottom: var(--pf-c-pagination--m-bottom--PaddingBottom);
    padding-left: var(--pf-c-pagination--m-bottom--PaddingLeft);
    background-color: var(--pf-c-pagination--m-bottom--BackgroundColor);
    box-shadow: var(--pf-c-pagination--m-bottom--BoxShadow); }
    .pf-c-pagination.pf-m-bottom .pf-c-pagination__nav-control .pf-c-button {
      --pf-c-button--PaddingTop: var(--pf-c-pagination--m-bottom__nav-control--c-button--PaddingTop);
      --pf-c-button--PaddingBottom: var(--pf-c-pagination--m-bottom__nav-control--c-button--PaddingBottom);
      outline-offset: var(--pf-c-pagination--m-bottom__nav-control--c-button--OutlineOffset); }
    .pf-c-pagination.pf-m-bottom.pf-m-static {
      --pf-c-pagination--m-bottom--MarginTop: 0;
      --pf-c-pagination--m-bottom--BorderTopWidth: 0;
      position: relative;
      box-shadow: none; }
    .pf-c-pagination.pf-m-bottom .pf-c-pagination__nav-control.pf-m-first,
    .pf-c-pagination.pf-m-bottom .pf-c-pagination__nav-control.pf-m-last,
    .pf-c-pagination.pf-m-bottom .pf-c-pagination__nav-page-select {
      display: none;
      visibility: hidden; }
    .pf-c-pagination.pf-m-bottom .pf-c-options-menu {
      position: absolute; }
    .pf-c-pagination.pf-m-bottom .pf-c-pagination__nav {
      display: flex;
      flex-basis: 100%;
      justify-content: space-between; }
    @media screen and (min-width: 768px) {
      .pf-c-pagination.pf-m-bottom {
        --pf-c-pagination--m-bottom--BorderTopWidth: 0;
        --pf-c-pagination--m-bottom--MarginTop: 0;
        position: relative;
        justify-content: flex-end;
        padding: var(--pf-c-pagination--m-bottom--md--PaddingTop) var(--pf-c-pagination--m-bottom--md--PaddingRight) var(--pf-c-pagination--m-bottom--md--PaddingBottom) var(--pf-c-pagination--m-bottom--md--PaddingLeft); }
        .pf-c-pagination.pf-m-bottom .pf-c-pagination__nav-control.pf-m-first,
        .pf-c-pagination.pf-m-bottom .pf-c-pagination__nav-control.pf-m-last,
        .pf-c-pagination.pf-m-bottom .pf-c-pagination__nav-page-select {
          display: block;
          visibility: visible; }
        .pf-c-pagination.pf-m-bottom .pf-c-options-menu {
          position: relative; }
        .pf-c-pagination.pf-m-bottom .pf-c-pagination__nav {
          display: inline-flex;
          flex-basis: auto; } }
  .pf-c-pagination .pf-c-options-menu__toggle {
    font-size: var(--pf-c-pagination--c-options-menu__toggle--FontSize); }
  .pf-c-pagination.pf-m-compact {
    --pf-c-pagination--child--MarginRight: var(--pf-c-pagination--m-compact--child--MarginRight); }

.pf-c-pagination__nav {
  display: inline-flex;
  justify-content: flex-end; }

.pf-c-pagination__nav-control .pf-c-button {
  padding-right: var(--pf-c-pagination__nav-control--c-button--PaddingRight);
  padding-left: var(--pf-c-pagination__nav-control--c-button--PaddingLeft);
  font-size: var(--pf-c-pagination__nav-control--c-button--FontSize); }

.pf-c-pagination.pf-m-compact .pf-c-pagination__nav-control + .pf-c-pagination__nav-control {
  margin-left: var(--pf-c-pagination--m-compact__nav-control--nav-control--MarginLeft); }

.pf-c-pagination__nav-page-select {
  display: flex;
  align-items: center;
  padding-right: var(--pf-c-pagination__nav-page-select--PaddingRight);
  padding-left: var(--pf-c-pagination__nav-page-select--PaddingLeft); }
  .pf-c-pagination__nav-page-select > * {
    font-size: var(--pf-c-pagination__nav-page-select--FontSize);
    white-space: nowrap; }
    .pf-c-pagination__nav-page-select > *:not(:last-child) {
      margin-right: var(--pf-c-pagination__nav-page-select--child--MarginRight); }
  .pf-c-pagination__nav-page-select .pf-c-form-control {
    width: var(--pf-c-pagination__nav-page-select--c-form-control--Width); }

@media screen and (min-width: 768px) {
  .pf-c-pagination__total-items {
    display: none;
    visibility: hidden; } }
